<template>
    <div>
      <svg
        v-if="boolean"
        :width="width"
        :height="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
<path d="M11.0853 13.593V21.593H3.08533V13.593H11.0853ZM12.0853 2.09302L17.5853 11.093H6.58533L12.0853 2.09302ZM17.5853 13.093C20.0853 13.093 22.0853 15.093 22.0853 17.593C22.0853 20.093 20.0853 22.093 17.5853 22.093C15.0853 22.093 13.0853 20.093 13.0853 17.593C13.0853 15.093 15.0853 13.093 17.5853 13.093Z" :fill="color"/>
</svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TrashIcon',
    props: {
      boolean: {
        type: Boolean,
        required: true,
      },
      width: {
        type: Number,
        default: 24,
      },
      height: {
        type: Number,
        default: 24,
      },
      color: {
        type: String,
        default: 'var(--settings-registration-current-event-button-background)',
      },
    },
  };
  </script>
  